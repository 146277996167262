$transition-duration: 500ms;
$transition: opacity $transition-duration ease-in-out,
    transform $transition-duration ease-in-out;

.carousel__step {
    &-enter,
    &-appear {
        opacity: 0;
        transform: translateX(100%);
        &-active {
            opacity: 1;
            transform: translateX(0%);
            transition: $transition;
        }
    }

    &-exit {
        opacity: 1;
        transform: translateX(0%);

        &-active {
            opacity: 0;
            transform: translateX(-100%);
            transition: $transition;
        }
    }
}
