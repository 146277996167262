@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: IBM Plex Sans;
        overflow: hidden;
        // font-size: 0.9rem;
    }
}

* {
    font-family: IBM Plex Sans;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* AG Grid customization */
.ag-theme-alpine {
    font-family: 'IBM Plex Sans';
    font-size: 1.125rem;

    --ag-row-border-color: #d5e8ea;
    --ag-odd-row-background-color: transparent;

    & .ag-root-wrapper {
        border: none;
    }

    & .ag-header {
        border-bottom: none;
    }

    & .ag-header-row {
        font-weight: normal;
    }

    & .ag-row {
        font-size: 1rem;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    & .ag-header-cell {
        font-weight: 500;
        font-size: 1.125rem;
    }

    & .escode-row .ag-cell {
        /* line-height has to equal row height defined on ag grid grid options prop */
        line-height: 51px;
    }
}

.ag-theme-alpine .ag-cell-focus {
    border: none !important;
    // Removes the border around the focused cell
}
