html {
    background: linear-gradient(
        140.52deg,
        #e6e9f0 25.89%,
        rgba(244, 254, 255, 0.76) 86.98%
    );
}

:root {
    --escode-header-height: 3.75rem;
    --escode-body-padding-y: 1.563rem;

    --escode-widget-height: 4rem;
    --escode-dashboard-widget-padding: 0.25rem;
    --escode-dashboard-widget-height: 2rem +
        var(--escode-dashboard-widget-padding);
    --escode-dashboard-widget-gap: 1rem;
}
