$transition: opacity 250ms ease-out, transform 300ms ease-out;

$from: -20px;
$to: 0px;

.dropdown-panel {
    &-enter,
    &-appear {
        opacity: 0;
        transform: translateY($from);
        &-active {
            opacity: 1;
            transform: translateY($to);
            transition: $transition;
        }
    }

    &-exit {
        opacity: 1;
        transform: translateY($to);

        &-active {
            opacity: 0;
            transform: translateY(-$from);
            transition: $transition;
        }
    }
}
