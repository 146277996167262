// used css styles as styles are being overridden somewhere else in app
$dark-blue: #000fff;
$light-blue: #e5e7ff;
$border-radius: 0.5rem;

button[type='button'] {
    &.selected {
        background-color: $dark-blue;
        border-radius: $border-radius;
    }

    &.selected-view {
        background-color: white;
        color: #2b343c;
    }

    &.unselected-view {
        background-color: #e9ecf2;
        color: #a1a5b1;
        &:hover {
            background-color: darken(#e9ecf2, 5%);
            color: darken(#a1a5b1, 10%);
        }
    }

    &.today-button {
        border-radius: $border-radius;
        border: 1px solid #a1a5b1;
    }

    &.is-today {
        color: #0073e5;
    }

    &.this-month {
        color: #2b343c;
    }

    &.not-this-month {
        color: #898f9e;
    }
}
